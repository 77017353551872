@import "../../assets/styles/constants";

@mixin pageBasic {
  display: grid;
  grid-template-rows: 10% 90%;
  height: 100%;
  padding: 20px;
  background: #eff1f1;
}

@mixin pageTopbarMiscContainer {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-column-gap: 10px;
}

//home page
.home-page-container {
  @include pageBasic();
  grid-template-rows: 100%;
  padding: 0px;
}

.home-page-topbar {
  @include pageTopbarMiscContainer();
  grid-template-columns: 30% 10% 20% 20% 20%;
}

//alarm page

.alarm-page-container {
  @include pageBasic();
}

.alarm-page-topbar {
  @include pageTopbarMiscContainer();
}

.alarm-page-mainbar {
  padding: 10px 0px;
}

//settings page
.settings-page-container {
  @include pageBasic();
  grid-template-rows: 10vh 70vh;
  grid-row-gap: 10px;
}

.settings-page-topbar {
  @include pageTopbarMiscContainer();
}

.settings-page-mainbar {
  padding: 10px 0px;
  display: grid;
  overflow: auto;
  grid-template-rows: 1fr;
}

.test-container {
  background: #fff;
  margin: 40px;
}

.alarm-settings-group-container {
  align-items: center;
  display: grid;
  background: #fff;
  grid-template-columns: 7% 48% 20% 20% 5%;
  justify-items: start;
  grid-column-gap: 0%;
  padding: 20px 10px;
  border-bottom: 1px solid $greyColor;
  // @include shadow();
}

.alarm-settings-icon-container {
  border-radius: 50%;
  height: 55px;
  width: 55px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
}
.alarm-settings-icon {
  font-size: 25px;
  color: white;
}

.alarm-name-desc-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.alarm-settings-name {
  font-size: 16px;
  color: $textBlackColor;
  line-height: 24px;
}

.alarm-settings-desc {
  font-size: 12px;
  color: $textGreyColor;
}

.alarm-settings-threshold-container {
  width: 13vw;
  margin: 0px 30px;
  height: 55px;
  display: grid;
  grid-template-rows: 1fr 2fr;
  padding: 8px 10px;
  border-radius: 3px;
  background: rgba(55, 71, 79, 0.07);
}

.settings-threshold-input {
  outline: 0;
  width: 10vw;
  font-size: 16px;
  border: none;
  background: transparent;
  width: 100%;
}
.settings-group-title {
  outline: 0;
  font-size: 12px;
  justify-self: start;
}

//vehicles page

.vehicles-page-container {
  @include pageBasic();
}

.vehicles-page-topbar {
  @include pageTopbarMiscContainer();
}

.vehicles-page-mainbar {
  padding: 10px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.vehicle-details-card-container {
  height: 240px;
  background: #fff;
  display: grid;
  padding: 10px;
  grid-template-rows: 0.5fr 0fr 2fr;
  border-radius: 3px;
  @include shadow();
}

.vehicle-deteils-card-title {
  display: grid;
  grid-template-columns: 1fr 7fr;
  font-size: 18px;
  align-items: center;
}

.vehicle-deteils-card-basic {
  display: grid;
  grid-template-rows: repeat(1fr, 4);
  grid-row-gap: 5px;
}

.vehicle-details-single-item-info {
  display: grid;
  font-size: 16px;
  grid-template-columns: 1fr 5fr;
}

//papers page

.papers-page-container {
  @include pageBasic();
  display: grid;
  grid-row-gap: 5vh;
  height: 90vh;
  grid-template-rows: 5vh 20vh 5vh 40vh;
}

.papers-page-topbar {
  @include pageTopbarMiscContainer();
}

.papers-page-mainbar {
}

.paperinfo-cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 30px;
}

.paperinfo-cards {
  border-radius: 4px;
  background: #fff;
  @include shadow();
  padding: 15px;
  height: 23vh;
  display: grid;
  grid-template-rows: 0.8fr 0.4fr 32px 1fr;
}

.paperinfo-cards-nodata {
  background: #fff;
  @include shadow();
  padding: 10px 10px;
  display: grid;
  grid-row-gap: 5px;
  grid-template-rows: 0.8fr 0.4fr;
}

.paperinfo-title h4 {
  font-size: 23px;
  color: #37474f;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 4px;
}

.paperinfo-date {
  display: grid;
  font-size: 14px;
  grid-template-columns: 1fr 1fr;
}

.papercard-button-group {
  @include gridTwoColumn();
}

.paper-update-btn-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

//report page

.report-page-container {
  @include pageBasic();
  grid-row-gap: 10px;
}

.report-page-topbar {
  @include pageTopbarMiscContainer();
}

.report-page-mainbar {
  display: grid;
  grid-template-columns: auto 350px;
  grid-column-gap: 0px;
}

.report-info-container {
  background: #fff;
  padding: 15px;
  overflow: auto;
}

.report-info-topbar {
}

.report-info-mainbar {
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
}

.report-summary-container {
  // display: grid;
  // grid-template-rows: 1fr 2fr;
  // grid-row-gap: 5px;
  // @include shadow();
  // background: #fff;
  // margin: 6px;
  // border-radius: 3px;
  // text-align: center;
  // font-family: "Poppins", sans-serif !important;

  width: calc(50% - 10px);
  margin: 5px;
  @include shadow();
  padding: 15px 10px;
  float: left;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
}

.report-summary-container .dashboard-item-title {
  text-transform: uppercase;
  font-size: 12px;
  display: block;
  letter-spacing: 2px;
  line-height: 16px;
  margin-bottom: 9px;
}

.dashboard-item-data {
  font-size: 20px;
  letter-spacing: 0.25px;
  line-height: 27px;
}

//trips page

.trip-page-container {
  @include pageBasic();
  grid-row-gap: 10px;
  height: 90vh;
}

.trip-page-topbar {
  @include pageTopbarMiscContainer();
  grid-template-columns: 18% 18% 18% 18% 18%;
  grid-column-gap: 2%;
}

.trip-page-mainbar {
  display: grid;
  margin-bottom: 0px;
  overflow: auto;
  grid-template-columns: auto 350px;
}

//fuel page
.fuel-page-container {
  @include pageBasic();
  grid-row-gap: 10px;
}

.fuel-page-topbar {
  @include pageTopbarMiscContainer();
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

//billing page

.billing-page-container {
  @include pageBasic();
  grid-template-rows: 3fr 8fr;
  grid-row-gap: 30px;
  height: 90vh;
}

.billing-page-topbar {
  @include pageTopbarMiscContainer();
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.payment-summary-container {
  @include shadow();
  padding: 10px;
  text-align: center;
  height: 70%;
  background: #fff;
  border-radius: 4px;
}
.payment-summary-title {
  font-size: 15px;
  color: rgb(86, 92, 87);
  padding: 5px;
  text-align: center;
}

.payment-summary-amount {
  margin-top: 10px;
  font-size: 40px;
}

//support

.support-page-container {
  @include pageBasic();
  grid-row-gap: 10px;
  // height: 90vh;
}

.support-page-topbar {
  @include pageTopbarMiscContainer();
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

//login
.page-login-container {
  display: grid;
  height: 100vh;
  background: #eff1f1;
}

.container-login-form {
  height: 70%;
  justify-self: center;
  align-self: center;
  border-radius: 5px;
  transition: 0.3s;
  padding: 20px;
  background: #fff;
  display: grid;
  grid-row-gap: 3%;
  @include shadow();
}

.container-login-form-forgot {
  height: 40%;
  max-width: 80%;
  justify-self: center;
  align-self: center;
  border-radius: 5px;
  transition: 0.3s;
  padding: 20px;
  background: #fff;
  display: grid;
  grid-row-gap: 3%;
  @include shadow();
}

.login-single-row {
  display: grid;
  // @include shadow();

  grid-template-columns: auto auto;
  border: 1px solid rgb(201, 199, 199);
  &::hover {
    @include shadow();
  }
}

.icon-login-form {
  align-self: center;
  padding: 10px;
  font-size: 20px;
  color: $textBlackColor;
}

.container-login-form:hover {
  @include shadow();
}

.sign-in-app-icon {
  justify-self: center;
}

.sign-in-app-icon:hover {
  cursor: pointer;
}

.input-text {
  width: 100%;
  background: #fff;
  color: $textBlackColor;
  padding: 10px;
  outline: 0;
  border-radius: 3px;
  border: none;
}

.sign-in-title {
  justify-self: center;
  color: #0079bf;
}

.remember-text {
  color: $textBlueColor;
  &::hover {
    cursor: pointer;
  }
}

.remember-text:hover {
  color: $textBlueColor;
}

.submit-container {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
}

//profile
.profile-container {
  margin-left: 50px;
  display: grid;
  height: 100vh;
  grid-template-rows: 1fr 10fr;
  background: #eff1f1;
}

.profile-form-container {
  align-self: center;
  justify-self: center;
  width: 40%;
  display: grid;
  grid-template-rows: 1fr;
}

.profile-form {
  @include shadow();
  background: #fff;
  padding: 50px;
  display: grid;
  grid-template-rows: 1fr;
  align-self: center;
  align-content: center;
}

.hor-line {
  height: 2px;
  margin-top: 1px solid;
}

.notification-bar {
  z-index: 111;
}
