@import "constants";

.text-shadow {
  background: $lightGreyColor;
  padding: 5px;
  border: none;
  border-radius: 5px;
  &:focus {
    outline: 0;
  }
}

.navbar-form {
  background: $greyColor;
  width: auto;

  color: rgb(41, 43, 44);
  border-radius: 4px;
  margin-left: 10px;
}

.form-controll {
  outline: 0;
  width: 100%;
  font-size: 14px;
  background: $appBackground;
  border: none;
}

.input-search {
  padding: 15px;
  display: grid;
  grid-template-columns: 30px calc(100% - 60px) 30px;
  background: #eff1f1;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
}




.text-with-icon-in-modal {
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 11fr;
  border: 1px solid rgba(203, 207, 206, 0.404)	;
  align-items: center;
  margin: 10px 0px;
  border-radius: 5px;
  padding-left: 10px;
}

.text-with-icon-in-modal-large {
  height: auto;
  display: grid;
  grid-template-columns: 1fr 11fr;
  border: 1px solid rgba(203, 207, 206, 0.404)	;
  margin: 10px 0px;
  border-radius: 5px;
  padding-left: 10px;
  padding: 5px;
  text-align: center;
}




.icon-text-input-box-large {
  height: auto;
  font-size: 15px;
  margin: 3px;
}

.icon-text-input-box {
  font-size: 15px;
  margin: 3px;
}



.grey-background {
  background: $greyColor;
  padding: 10px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  width: 95%;
  &:focus {
    outline: 0;
  }
}

.dropdown-with-title {
  margin-top: 10px;
}

.title-over-dropdown {
  font-size: 10px;
  margin-left: 10px;
}
