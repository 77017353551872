
.btn-notification{
    justify-self: center;
    font-size: 30px;
}

.notification-item{
    height: auto;
    width: 250px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 5px;
    box-shadow: 0 4px 4px 0 #eee;
}

.notification-item:hover{
    cursor: pointer;
}


.notification-item-icon{
    height: 60px;
    align-content: center;
    font-size: 30px;
    padding: 10px;
}

.notification-item-details-title{
    height: auto;
    display: grid;
    font-size: 15px;
}

.notification-item-details{
    display: grid;
    grid-row-gap: 5px;
    line-height: 1.2rem;
    align-content: center;
    font-size: 12px;
    /* grid-template-rows: 2fr 1fr 1fr; */
}


.btn-noti-see-more{
    padding: 10px;
    width: 100%;
    color: blue;
    text-align: center;
}

.popup-content.notification-container-content {
    width: inherit !important;
    height: 400px;
    overflow: auto;
}
