$blueColor: #0079bf;
$lightBlueColor: #0079bf31;

$redColor: #dc143c;
$lightRedColor: #df6a814f;

$greenColor: rgb(26, 146, 66);
$lightGreenColor: rgba(76, 196, 116, 0.438);

$yellowColor: rgb(153, 145, 26);
$lightYellowColor: rgba(235, 214, 27, 0.568);

$orangeColor: #ff8c00;
$deepOrangeColor: #e0740e;
$lightOrangeColor: rgba(255, 166, 0, 0.473);
$whiteColor: #fff;
$greyColor: #eeeeee;
$lightGreyColor: rgba(238, 238, 238, 0);
$blackColor: #37474f;
$greenColor: rgb(76, 196, 116);
$deepGreenColor: #098833;

$textRedColor: #fe3935;
$textBlueColor: #0079bf;
$textBlackColor: #37474f;
$textGreyColor: #37474f98;

$appBackground: #a1c0c0;

$appBackground: #eff1f1;
$magentaColor: #19b4b4;

.text-red {
  color: $textRedColor;
}

.text-black {
  color: $textBlackColor;
}

.text-blue {
  color: $textBlueColor;
}
.text-orange {
  color: $orangeColor;
}

.text-deep-orange {
  color: $deepOrangeColor;
}
.text-green {
  color: rgb(76, 196, 116);
}

.text-deep-green {
  color: $deepGreenColor;
}
.text-magenta {
  color: $magentaColor;
}

.text-green-deep {
  color: rgb(69, 179, 105);
}

.background-color {
  background: #eff1f1;
}

@mixin shadow {
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

@mixin gridTwoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
