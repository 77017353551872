body {
  margin: 0;
  padding: 0;
  letter-spacing: 0.8px;
  line-height: 1rem;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.root {
  height: 100vh; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-deep-orange {
  color: #e0740e; }

.text-green {
  color: #4cc474; }

.text-deep-green {
  color: #098833; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-color {
  background: #eff1f1; }

.home-page-container {
  display: grid;
  grid-template-rows: 10% 90%;
  height: 100%;
  padding: 20px;
  background: #eff1f1;
  grid-template-rows: 100%;
  padding: 0px; }

.home-page-topbar {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-column-gap: 10px;
  grid-template-columns: 30% 10% 20% 20% 20%; }

.alarm-page-container {
  display: grid;
  grid-template-rows: 10% 90%;
  height: 100%;
  padding: 20px;
  background: #eff1f1; }

.alarm-page-topbar {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-column-gap: 10px; }

.alarm-page-mainbar {
  padding: 10px 0px; }

.settings-page-container {
  display: grid;
  grid-template-rows: 10% 90%;
  height: 100%;
  padding: 20px;
  background: #eff1f1;
  grid-template-rows: 10vh 70vh;
  grid-row-gap: 10px; }

.settings-page-topbar {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-column-gap: 10px; }

.settings-page-mainbar {
  padding: 10px 0px;
  display: grid;
  overflow: auto;
  grid-template-rows: 1fr; }

.test-container {
  background: #fff;
  margin: 40px; }

.alarm-settings-group-container {
  -webkit-align-items: center;
          align-items: center;
  display: grid;
  background: #fff;
  grid-template-columns: 7% 48% 20% 20% 5%;
  justify-items: start;
  grid-column-gap: 0%;
  padding: 20px 10px;
  border-bottom: 1px solid #eeeeee; }

.alarm-settings-icon-container {
  border-radius: 50%;
  height: 55px;
  width: 55px;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  margin: auto; }

.alarm-settings-icon {
  font-size: 25px;
  color: white; }

.alarm-name-desc-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%; }

.alarm-settings-name {
  font-size: 16px;
  color: #37474f;
  line-height: 24px; }

.alarm-settings-desc {
  font-size: 12px;
  color: #37474f98; }

.alarm-settings-threshold-container {
  width: 13vw;
  margin: 0px 30px;
  height: 55px;
  display: grid;
  grid-template-rows: 1fr 2fr;
  padding: 8px 10px;
  border-radius: 3px;
  background: rgba(55, 71, 79, 0.07); }

.settings-threshold-input {
  outline: 0;
  width: 10vw;
  font-size: 16px;
  border: none;
  background: transparent;
  width: 100%; }

.settings-group-title {
  outline: 0;
  font-size: 12px;
  justify-self: start; }

.vehicles-page-container {
  display: grid;
  grid-template-rows: 10% 90%;
  height: 100%;
  padding: 20px;
  background: #eff1f1; }

.vehicles-page-topbar {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-column-gap: 10px; }

.vehicles-page-mainbar {
  padding: 10px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px; }

.vehicle-details-card-container {
  height: 240px;
  background: #fff;
  display: grid;
  padding: 10px;
  grid-template-rows: 0.5fr 0fr 2fr;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2); }

.vehicle-deteils-card-title {
  display: grid;
  grid-template-columns: 1fr 7fr;
  font-size: 18px;
  -webkit-align-items: center;
          align-items: center; }

.vehicle-deteils-card-basic {
  display: grid;
  grid-template-rows: repeat(1fr, 4);
  grid-row-gap: 5px; }

.vehicle-details-single-item-info {
  display: grid;
  font-size: 16px;
  grid-template-columns: 1fr 5fr; }

.papers-page-container {
  display: grid;
  grid-template-rows: 10% 90%;
  height: 100%;
  padding: 20px;
  background: #eff1f1;
  display: grid;
  grid-row-gap: 5vh;
  height: 90vh;
  grid-template-rows: 5vh 20vh 5vh 40vh; }

.papers-page-topbar {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-column-gap: 10px; }

.paperinfo-cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 30px; }

.paperinfo-cards {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  height: 23vh;
  display: grid;
  grid-template-rows: 0.8fr 0.4fr 32px 1fr; }

.paperinfo-cards-nodata {
  background: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  display: grid;
  grid-row-gap: 5px;
  grid-template-rows: 0.8fr 0.4fr; }

.paperinfo-title h4 {
  font-size: 23px;
  color: #37474f;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 4px; }

.paperinfo-date {
  display: grid;
  font-size: 14px;
  grid-template-columns: 1fr 1fr; }

.papercard-button-group {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.paper-update-btn-container {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.report-page-container {
  display: grid;
  grid-template-rows: 10% 90%;
  height: 100%;
  padding: 20px;
  background: #eff1f1;
  grid-row-gap: 10px; }

.report-page-topbar {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-column-gap: 10px; }

.report-page-mainbar {
  display: grid;
  grid-template-columns: auto 350px;
  grid-column-gap: 0px; }

.report-info-container {
  background: #fff;
  padding: 15px;
  overflow: auto; }

.report-summary-container {
  width: calc(50% - 10px);
  margin: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 15px 10px;
  float: left;
  border-radius: 4px; }
  .report-summary-container:hover {
    cursor: pointer; }

.report-summary-container .dashboard-item-title {
  text-transform: uppercase;
  font-size: 12px;
  display: block;
  letter-spacing: 2px;
  line-height: 16px;
  margin-bottom: 9px; }

.dashboard-item-data {
  font-size: 20px;
  letter-spacing: 0.25px;
  line-height: 27px; }

.trip-page-container {
  display: grid;
  grid-template-rows: 10% 90%;
  height: 100%;
  padding: 20px;
  background: #eff1f1;
  grid-row-gap: 10px;
  height: 90vh; }

.trip-page-topbar {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-column-gap: 10px;
  grid-template-columns: 18% 18% 18% 18% 18%;
  grid-column-gap: 2%; }

.trip-page-mainbar {
  display: grid;
  margin-bottom: 0px;
  overflow: auto;
  grid-template-columns: auto 350px; }

.fuel-page-container {
  display: grid;
  grid-template-rows: 10% 90%;
  height: 100%;
  padding: 20px;
  background: #eff1f1;
  grid-row-gap: 10px; }

.fuel-page-topbar {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.billing-page-container {
  display: grid;
  grid-template-rows: 10% 90%;
  height: 100%;
  padding: 20px;
  background: #eff1f1;
  grid-template-rows: 3fr 8fr;
  grid-row-gap: 30px;
  height: 90vh; }

.billing-page-topbar {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-column-gap: 10px;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr; }

.payment-summary-container {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  text-align: center;
  height: 70%;
  background: #fff;
  border-radius: 4px; }

.payment-summary-title {
  font-size: 15px;
  color: #565c57;
  padding: 5px;
  text-align: center; }

.payment-summary-amount {
  margin-top: 10px;
  font-size: 40px; }

.support-page-container {
  display: grid;
  grid-template-rows: 10% 90%;
  height: 100%;
  padding: 20px;
  background: #eff1f1;
  grid-row-gap: 10px; }

.support-page-topbar {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.page-login-container {
  display: grid;
  height: 100vh;
  background: #eff1f1; }

.container-login-form {
  height: 70%;
  justify-self: center;
  align-self: center;
  border-radius: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding: 20px;
  background: #fff;
  display: grid;
  grid-row-gap: 3%;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2); }

.container-login-form-forgot {
  height: 40%;
  max-width: 80%;
  justify-self: center;
  align-self: center;
  border-radius: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding: 20px;
  background: #fff;
  display: grid;
  grid-row-gap: 3%;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2); }

.login-single-row {
  display: grid;
  grid-template-columns: auto auto;
  border: 1px solid #c9c7c7; }
  .login-single-row::hover {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2); }

.icon-login-form {
  -webkit-align-self: center;
          align-self: center;
  padding: 10px;
  font-size: 20px;
  color: #37474f; }

.container-login-form:hover {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2); }

.sign-in-app-icon {
  justify-self: center; }

.sign-in-app-icon:hover {
  cursor: pointer; }

.input-text {
  width: 100%;
  background: #fff;
  color: #37474f;
  padding: 10px;
  outline: 0;
  border-radius: 3px;
  border: none; }

.sign-in-title {
  justify-self: center;
  color: #0079bf; }

.remember-text {
  color: #0079bf; }
  .remember-text::hover {
    cursor: pointer; }

.remember-text:hover {
  color: #0079bf; }

.submit-container {
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  grid-template-columns: 1fr 1fr; }

.profile-container {
  margin-left: 50px;
  display: grid;
  height: 100vh;
  grid-template-rows: 1fr 10fr;
  background: #eff1f1; }

.profile-form-container {
  align-self: center;
  justify-self: center;
  width: 40%;
  display: grid;
  grid-template-rows: 1fr; }

.profile-form {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 50px;
  display: grid;
  grid-template-rows: 1fr;
  align-self: center;
  -webkit-align-content: center;
          align-content: center; }

.hor-line {
  height: 2px;
  margin-top: 1px solid; }

.notification-bar {
  z-index: 111; }


.side-bar-icon-container-logo a {
    cursor: pointer;
}

.sidebar {
    height: 100vh;
    width: 60px;
    /* position: fixed; */
    z-index: 1;
    background-color: #fff;
    overflow-x: hidden;
    display: grid;
    grid-template-rows: repeat(10 , 2fr )  10fr 2fr;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center;
  }


  .a:focus{
    background:   #0079BF;
  }

  .side-menus {
    width: 100%;
}

  .sidebar div.side-menus a {
    font-size: 28px;
	  color:  #37474f;
    display: block;
    padding: 26%;
    -webkit-justify-content: center;
            justify-content: center;
  }


  .sidebar div.side-menus a:hover{
    padding: 26%;
    cursor: pointer;
	  color: #fff;
    -webkit-transition: 300ms;
    transition: 300ms;
    background:  #0079BF;  ;
    text-decoration: none;
  }

  .sidebar div.side-menus a:focus{
    cursor: pointer;
    color:    #0079BF;
    background:  #fff;
    -webkit-transition: 300ms;
    transition: 300ms;
    text-decoration: none;
  }


  .side-bar-icon-container-logo{
    padding: 10px;
  }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-deep-orange {
  color: #e0740e; }

.text-green {
  color: #4cc474; }

.text-deep-green {
  color: #098833; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-color {
  background: #eff1f1; }

.app-container {
  background: #eff1f1 !important;
  display: grid;
  grid-template-columns: 60px auto;
  height: 100vh !important;
  font-family: "Poppins", sans-serif !important; }

.app-sidebar {
  width: 60px;
  height: 100vh;
  background: #37474f; }

.app-top-mainbar {
  display: grid;
  grid-template-rows: 10% 90%; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-green {
  color: #4cc474; }

.text-green-deep {
  color: #45b369; }

.background-color {
  background: #eff1f1; }

::-webkit-scrollbar-track {
  background-color: #ddd; }

::-webkit-scrollbar {
  width: 6px;
  background-color: #ddd; }

::-webkit-scrollbar-thumb {
  background-color: #0079BF; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-deep-orange {
  color: #e0740e; }

.text-green {
  color: #4cc474; }

.text-deep-green {
  color: #098833; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-color {
  background: #eff1f1; }

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  display: block; }

.modal-loader {
  background: #fff;
  display: block; }

.modal-loader-container {
  position: fixed;
  background: transparent;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 5px; }

.modal-locationshare-container {
  position: fixed;
  background: #fff;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 5px;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  height: auto;
  width: 50%;
  display: grid;
  font-size: 12px;
  grid-template-rows: 1fr;
  padding: 15px; }

.modal-location-share-url {
  display: grid;
  grid-template-columns: 5fr auto; }

.modal-radius-set {
  position: fixed;
  background: #fff;
  top: 20%;
  left: 20%;
  height: 70%;
  width: 60%;
  display: grid;
  grid-template-rows: 20% 80%; }

.modal-radius-set-topbar {
  display: grid;
  grid-template-columns: 80% 20%; }

.modal-fuel-container {
  position: fixed;
  background: #fff;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 5px;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  height: auto;
  width: 400px;
  display: grid;
  font-size: 12px;
  grid-template-rows: 1fr;
  padding: 15px; }

.image-with-upload-btn {
  display: grid;
  grid-template-rows: 5fr 1fr; }

.modal-logview-container {
  position: fixed;
  background: #fff;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 5px;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  width: 400px;
  padding: 30px; }

.modal-trendview-container {
  position: fixed;
  background: #fff;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 5px;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  height: auto;
  width: auto;
  padding: 30px; }

.modal-trend-resource-date {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  padding: 10px; }

.modal-vehiclepaper-container {
  position: fixed;
  background: #fff;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 5px;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  display: grid;
  grid-template-rows: 40px 60px 250px 60px;
  grid-row-gap: 10px;
  width: 400px;
  height: auto;
  padding: 10px 20px; }

.vehicle-paper-image {
  height: 200px;
  width: auto; }

.btn-choose-file {
  background: #fff; }

.date-text-paper-update {
  padding: 15px; }

/* maintenance entry */
.modal-maintenance-container {
  position: fixed;
  background: #fff;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 5px;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  display: grid;
  margin: 10px;
  width: 400px;
  font-size: 12px;
  grid-template-rows: 1fr;
  padding: 20px; }

.modal-title-cross {
  display: grid;
  grid-template-columns: 8fr 1fr;
  font-size: 18px; }

.modal-title-cross:hover {
  cursor: pointer; }

.modal-title {
  margin: 10px;
  font-size: 20px; }

.modal-cross {
  font-size: 20px;
  -webkit-align-self: center;
          align-self: center;
  justify-self: right; }

.modal-replay-details {
  height: auto;
  display: grid;
  width: 25%;
  grid-row-gap: 25px;
  padding: 15px;
  z-index: 11;
  background: #fff;
  position: fixed;
  top: 15%;
  left: 15%;
  border-radius: 5px; }

.alarm-replay-title-time-cross {
  display: grid;
  grid-template-columns: 43% 44% 15%;
  -webkit-align-items: baseline;
          align-items: baseline; }

.alarm-replay-title {
  color: #dc143c;
  font-size: 18px; }

.alarm-replay-time {
  font-size: 12px;
  color: #757e78;
  justify-self: right; }

.alarm-replay-close {
  font-size: 20px;
  justify-self: right;
  padding: 10px; }

.alarm-replay-type-value {
  margin-top: 10px;
  font-size: 14px;
  color: #37474f;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 5px; }

.alarm-replay-image {
  margin-top: 10px;
  height: auto;
  justify-self: center;
  width: auto; }

.alarm-replay-vehicletitle {
  margin-top: 10px;
  font-size: 14px; }

.alarm-replay-license {
  margin-top: 10px;
  font-size: 14px; }

.alarm-replay-driver {
  margin-top: 10px;
  font-size: 14px; }

.btn-replay-play-pause {
  padding: 10px;
  font-size: 15px;
  text-align: center;
  -webkit-align-self: center;
          align-self: center;
  justify-items: center;
  background: #eee; }

.btn-replay-play-pause:hover {
  cursor: pointer; }

.modal-vehicle-details-container {
  position: fixed;
  width: 30%;
  height: 88%;
  right: 10px;
  bottom: 1%;
  padding: 15px;
  line-height: 1.2;
  word-spacing: 2px;
  letter-spacing: 0.8px;
  background: #fff;
  border-radius: 5px;
  overflow: auto;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37); }

.speedometer-container {
  height: 200px;
  width: 200px; }

.vehicle-details-topbar-container {
  -webkit-align-items: center;
          align-items: center;
  display: grid;
  grid-template-rows: 20px 70px 30px;
  grid-row-gap: 5px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(178, 189, 189, 0.274); }

.vehicle-details-title-cross {
  font-size: 20px;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  grid-template-columns: auto 24px; }

.vehicle-details-title {
  text-align: left;
  display: grid; }

.vehicle-details-timeago {
  font-size: 10px;
  line-height: 1.3; }

.vehicle-details-driver-vehicle {
  line-height: 1.5;
  -webkit-align-items: center;
          align-items: center;
  font-size: 14px;
  display: grid;
  color: #585a5c;
  grid-row-gap: 1px; }

.mini-person-icon {
  font-size: 12px; }

.btn-track {
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  font-size: 16px;
  margin: 15px 0px;
  width: 100%;
  color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  background: #0079bf;
  outline: 0; }

.btn-track:hover {
  -webkit-transition: 300ms;
  transition: 300ms; }

.btn-track:focus {
  -webkit-transition: 300ms;
  transition: 300ms;
  border: none;
  outline: 0; }

.btn-exit-track {
  margin: 15px 0px;
  color: #dc143c;
  width: 100%;
  padding: 15px 10px;
  border-radius: 5px;
  border: 1px solid #dc143c;
  background: #fff;
  outline: 0; }

.btn-exit-track:hover {
  background: #dc143c;
  color: #fff;
  -webkit-transition: 300ms;
  transition: 300ms; }

.btn-exit-track:focus {
  outline: 0; }

.vehicle-details-title-over-data {
  margin: 20px 0px;
  font-size: 12px; }

.vehicle-details-info-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  border-bottom: 1px solid rgba(165, 168, 166, 0.322);
  margin-bottom: 10px; }

.dashboard-single-item-container {
  display: grid;
  grid-template-rows: 1fr 3fr;
  grid-row-gap: 10px;
  font-family: "Poppins", sans-serif !important; }

.dashboard-item-title {
  font-size: 10px;
  color: #020202; }

.dashboard-item-data {
  font-size: 20px;
  -webkit-align-items: center;
          align-items: center; }

.device-details-image-container {
  display: grid;
  grid-template-rows: 4fr auto;
  grid-row-gap: 5px; }

.device-details-image {
  height: 150px; }

.device-details-switch-btn {
  color: #0079bf;
  height: 40px;
  width: 70%;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  outline: 0;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37); }
  .device-details-switch-btnhover {
    outline: 0; }
  .device-details-switch-btn::focus {
    outline: 0; }

.loader-spinner-anywhere {
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-self: center;
          align-self: center;
  justify-self: center; }

.paper-view-modal-img {
  height: 230px;
  width: 200px; }

.modal-trip-details {
  display: grid;
  background: #fff;
  padding: 10px;
  -webkit-align-items: center;
          align-items: center;
  grid-template-rows: auto auto auto auto;
  grid-row-gap: 2%;
  overflow: auto;
  border-radius: 4px;
  z-index: 11;
  line-height: 1.2;
  word-spacing: 2px;
  letter-spacing: 1px;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37); }

.trip-details-title-cross {
  font-size: 20px;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  grid-template-columns: auto 24px;
  border-bottom: solid 1px #eeeeee; }

.trip-details-vehicle-name {
  font-size: 12px;
  line-height: 1.5;
  margin: 10px 2px;
  color: rgba(69, 71, 71, 0.801); }

trip-details-title {
  text-align: left;
  display: grid; }

.trip-details-srcdest-container {
  display: grid;
  font-size: 14px;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 10px; }

.trip-details-srcdest-single {
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  grid-template-columns: 8% 72% 20%; }

.trip-details-srcdest-time {
  display: grid;
  font-size: 10px;
  background: #0079bf31;
  color: #0079bf;
  padding: 8px 4px;
  letter-spacing: 1px;
  border-radius: 4px;
  text-align: center; }

.trip-details-info-container {
  border-top: 1px solid #eeeeee;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr auto;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  border-bottom: 1px solid rgba(165, 168, 166, 0.322);
  margin-bottom: 10px;
  padding-top: 10px; }

.report-single-item-container {
  display: grid;
  grid-template-rows: 1fr 3fr;
  grid-row-gap: 10px;
  font-family: "Poppins", sans-serif !important; }

.modal-trip-summary {
  display: grid;
  background: #fff;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  height: inherit !important;
  overflow: auto;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  border-radius: 5px;
  font-family: "Poppins", sans-serif !important;
  padding: 0px 12px; }

.trip-summary-single {
  display: grid;
  border-bottom: 1px solid #eeeeee;
  padding: 12px 0px;
  grid-row-gap: 5px;
  line-height: 1.5;
  word-spacing: 2px;
  letter-spacing: 0.5px;
  max-height: 200px; }
  .trip-summary-single:hover {
    cursor: pointer; }

.trip-srcdest-time {
  padding: 7px 0px;
  border-radius: 4px;
  font-size: 11px;
  letter-spacing: 0.5px;
  word-spacing: 0px;
  text-align: center; }

.icon-big {
  font-size: 16px;
  -webkit-align-self: center;
          align-self: center; }

.single-trip-single-row-major {
  display: grid;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 12px;
  font-weight: 500;
  grid-column-gap: 2%;
  grid-template-columns: 6% 68% 20%; }

.single-trip-single-row-minor {
  display: grid;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 11px;
  color: rgba(112, 121, 120, 0.842);
  grid-template-columns: 10% 65% 25%; }

.report-single-trip {
  display: grid;
  grid-template-rows: 1fr 0.8fr 0.8fr 0.8fr 1fr; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-deep-orange {
  color: #e0740e; }

.text-green {
  color: #4cc474; }

.text-deep-green {
  color: #098833; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-color {
  background: #eff1f1; }

.dropdown-basic-container {
  display: grid;
  grid-template-columns: 1.5fr 8.5fr;
  letter-spacing: 1px;
  background: #fff;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px; }

.datepicker-container {
  display: grid;
  grid-template-columns: 8.5fr 1.5fr;
  letter-spacing: 1px;
  background: #fff;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px; }

.dropdown-basic-icon-container {
  display: grid;
  -webkit-align-content: center;
          align-content: center;
  justify-items: center;
  font-size: 25px; }

.dropdown-basic-title {
  font-size: 10px;
  padding: 5px 10px; }

.dropdown-basic-data {
  padding: 2px 5px;
  font-size: 14px; }

.dropdown-basic-selection {
  background: #fff;
  border: none;
  outline: 0;
  width: 100%; }

.date-picker {
  font-size: 14px;
  outline: 0;
  border: none;
  width: 100%;
  padding: 2px 10px; }

.dropdown-severity-container {
  -webkit-align-items: center;
  align-items: center;
  justify-self: center;
  display: grid;
  grid-template-columns: 1fr 9fr;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative; }

.severity-status-color {
  position: absolute;
  width: 11px;
  height: 11px;
  display: block;
  left: 10px;
  border-radius: 50%; }

.dropdown-severity-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  padding: 15px 30px;
  font-size: 12px;
  outline: 0;
  background: rgba(55, 71, 79, 0.07);
  border-radius: 4px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAAGXcA1uAAAABGdBTUEAALGPC/xhBQAAARZJREFUSA1jYMAGbHwC9bGJUy5m6Rlob+HuP49yk7Cb4NDQwIJdBihq7u7/H4i/4FQw2CTMvQOMQY4GAkaQ20AhR5QHQMFMlMLB5uPB6R5s+QE9pTEhO/33739HLTwCZ4PEGhoamECR+OP4JT1kNeAYRRYARRgjI9Py////pTAxMTkc377+ILI8VjZIEyi2sUqOCo6GAC1DwMLTvxyUtEFJHVZGIdsHSvrQMus/SC2yHDKbGZmDzH5y5+ZROTWtA8As0T5nyYpWWVUtmce3r29hBOaTH+z8c28/ebEeqF4NmF3cTmzfsBRZLzIbI68hS8LYoGz079+/rUA+N1TsK9Bgb6LyIcwQYmhrvwgpECZG7aiawRMCANF1XIy8CkxpAAAAAElFTkSuQmCC);
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  width: 140px;
  height: 55px; }

.dropdown-resources-normal {
  padding: 5px 10px;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  font-size: 14px;
  outline: 0;
  border: none; }

.dropdown-resources-modal {
  padding: 10px;
  padding-left: 10px;
  background: #eeeeee;
  border-radius: 5px;
  outline: 0;
  border: none;
  width: 100%; }

.dropdown-modal-container {
  background: rgba(238, 238, 238, 0);
  border-radius: 5px;
  margin: 5px; }

.dropdown-modal-selection {
  background: rgba(238, 238, 238, 0);
  border: none;
  outline: 0;
  padding: 10px;
  width: 100%; }

.dropdown-modal-option {
  background: rgba(238, 238, 238, 0);
  padding: 10px;
  height: 40px; }

.dropdown-with-icon-in-modal {
  display: grid;
  grid-template-columns: 1fr 12fr;
  background: #eeeeee;
  -webkit-align-items: center;
          align-items: center;
  margin: 0px 0px;
  padding: 4px 8px;
  border-radius: 4px;
  margin-bottom: 10px; }

.icon-dropdown-box {
  font-size: 15px;
  margin-left: 5px; }

.date-picker-modal {
  padding: 10px;
  width: 100%;
  text-align: center;
  border: none;
  outline: 0;
  background: #eeeeee; }

.icon-date-alarm {
  font-size: 25px; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-deep-orange {
  color: #e0740e; }

.text-green {
  color: #4cc474; }

.text-deep-green {
  color: #098833; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-color {
  background: #eff1f1; }

.btn-exit-tracker {
  border: 1px solid #0079bf;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 5px;
  z-index: 11;
  background: transparent;
  background: #fff;
  color: #0079bf;
  font-style: bold;
  -webkit-align-content: center;
          align-content: center;
  position: fixed;
  display: block;
  margin-bottom: 0%;
  position: fixed;
  right: 40%;
  top: 90%;
  -webkit-transition: all 0.2s ease-in 0s;
  transition: all 0.2s ease-in 0s;
  z-index: 9999;
  cursor: pointer; }

.btn-exit-tracker:hover {
  background: #0079bf;
  color: #fff; }

.btn-pay {
  color: #0079bf;
  border-radius: 5px;
  padding: 8px 30px;
  background: #fff; }

.btn-pay:hover {
  -webkit-transition: 30ms;
  transition: 30ms; }

.btn-icon-txt-blue-filled {
  justify-self: right;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 15px;
  padding: 2px;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 5px;
  background: #0079bf;
  color: #fff;
  padding-left: 15px;
  padding-right: 30px;
  height: 50px;
  min-width: 100px;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .btn-icon-txt-blue-filled:hover {
    cursor: pointer;
    box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37); }

.btn-download-report {
  padding: 10px 20px;
  height: 40px;
  min-width: 100px;
  margin: 10px;
  background: #0079bf;
  color: #fff;
  border-radius: 4px;
  font-size: 14px; }
  .btn-download-report:hover {
    cursor: pointer;
    box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37); }

.btn-icon-txt-white-outlined-left {
  justify-self: left;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 15px;
  padding: 8px;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 5px;
  background: #fff;
  color: #0079bf;
  padding: 2px 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .btn-icon-txt-white-outlined-left:hover {
    cursor: pointer; }

.btn-icon-txt-white-outlined-right {
  justify-self: right;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 15px;
  padding: 8px;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 5px;
  background: #fff;
  color: #0079bf;
  padding: 2px 10px; }
  .btn-icon-txt-white-outlined-right:hover {
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s; }

.valid-paper {
  justify-self: left;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 15px;
  padding: 6px;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 5px;
  background: #0079bf31;
  color: #0079bf;
  padding: 2px 10px;
  padding-right: 30px; }

.expired-paper {
  justify-self: left;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 15px;
  padding: 6px;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 5px;
  background: #df6a814f;
  color: #dc143c;
  padding: 2px 10px;
  padding-right: 30px; }

.warning-paper {
  justify-self: left;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 15px;
  padding: 6px;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 5px;
  background: rgba(255, 166, 0, 0.473);
  color: #ff8c00;
  padding: 2px 10px;
  padding-right: 30px; }

.save-btn {
  margin-top: 20px;
  background: #447dd1;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  padding-top: 15px;
  display: grid;
  grid-template-columns: 1fr 10fr; }

.save-text {
  margin-right: 15%; }

.btn-icon {
  font-size: 18px; }

.btn-signin {
  justify-self: right;
  background: #0079bf;
  color: #fff;
  padding: 15px 20px;
  border-radius: 4px; }
  .btn-signin:hover {
    box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
    -webkit-transition: 0.3s;
    transition: 0.3s; }

.btn-group-cntrl-map {
  top: 15%;
  left: 80px;
  position: fixed;
  display: grid;
  grid-template-rows: 1fr;
  border-radius: 3px; }

.btn-zoom-in {
  color: #131313;
  background: #fff;
  font-size: 24px;
  padding: 6px 5px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-transition: 0.1s;
  transition: 0.1s; }

.btn-zoom-out {
  background: #fff;
  font-size: 24px;
  padding: 6px 5px;
  -webkit-transition: 0.1s;
  transition: 0.1s; }

.btn-my-location {
  background: #fff;
  font-size: 20px;
  padding: 6px 5px;
  padding-left: 7px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-transition: 0.1s;
  transition: 0.1s; }

.btn-my-location:hover {
  background: #0079bf;
  color: #fff;
  cursor: pointer; }

.btn-zoom-in:hover {
  background: #0079bf;
  color: #fff;
  cursor: pointer; }

.btn-zoom-out:hover {
  background: #0079bf;
  color: #fff;
  cursor: pointer; }

.search-result-list {
  position: fixed;
  top: 9.5vh;
  left: 70px;
  width: 27.5vw;
  max-height: 400px;
  max-width: 600px;
  z-index: 10;
  overflow: auto;
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

.search-result-list-item-vehicle {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  height: auto;
  text-align: left;
  border-bottom: 1px rgba(221, 219, 219, 0.61) solid; }

.search-result-list-item-vehicle:hover {
  background: #e7ecea; }

.search-vehicle-item-details {
  display: grid;
  grid-template-rows: 2fr 1fr; }

.search-vehicle-item-icon {
  font-size: 20px;
  -webkit-align-self: center;
          align-self: center;
  justify-self: center; }

.search-vehicle-item-title {
  font-size: 15px;
  margin-left: 5px;
  -webkit-align-self: center;
          align-self: center; }

.search-vehicle-item-driver {
  font-size: 14px;
  -webkit-align-self: center;
          align-self: center; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-deep-orange {
  color: #e0740e; }

.text-green {
  color: #4cc474; }

.text-deep-green {
  color: #098833; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-color {
  background: #eff1f1; }

.table {
  border: none;
  overflow: auto; }

.tablename-btn-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.header-text {
  font-size: 20px;
  -webkit-align-self: center;
          align-self: center; }

.table-alarm {
  border: none;
  overflow: auto;
  overflow: auto;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  height: 70vh; }

.table-maintenance {
  border: none;
  overflow: auto;
  overflow: auto;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  height: 40vh; }

.table-fuel {
  border: none;
  overflow: auto;
  overflow: auto;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  height: 70vh; }

.table-bill {
  border: none;
  overflow: auto;
  overflow: auto;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  height: 50vh; }

.table-support {
  border: none;
  overflow: auto;
  overflow: auto;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  height: 70vh; }

.table td {
  border: none;
  font-size: 12px;
  -webkit-align-items: center;
          align-items: center; }

.td-center {
  -webkit-justify-content: center;
          justify-content: center;
  justify-items: center;
  justify-self: center; }

.table th {
  font-size: 14px;
  border: none; }

.replay-icon {
  font-size: 20px; }

.row:nth-child(even) {
  background-color: #f2f2f2; }

.center-td {
  justify-self: center; }

.btn-load-more {
  text-align: center;
  width: 100%;
  color: #0079bf; }

.btn-load-more:focus {
  outline: 0;
  border: none; }

.issue-open {
  color: #ff8c00;
  border: 1px solid;
  padding: 5px 20px;
  border-radius: 4px; }

.issue-reopen {
  color: #0079bf;
  border: 1px solid;
  padding: 5px 20px;
  border-radius: 4px; }

.issue-resolved {
  color: #4cc474;
  border: 1px solid;
  padding: 5px 20px;
  border-radius: 4px; }

.issue-closed {
  color: #dc143c;
  border: 1px solid;
  padding: 5px 20px;
  border-radius: 4px; }

.issue-duplicate {
  color: #4cc474;
  border: 1px solid;
  padding: 5px 20px;
  border-radius: 4px; }

.table-item-no-data {
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center !important;
  font-size: 15px !important;
  width: 100%; }

.due-status {
  padding: 5px 15px;
  text-align: center;
  border-radius: 5px; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-deep-orange {
  color: #e0740e; }

.text-green {
  color: #4cc474; }

.text-deep-green {
  color: #098833; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-color {
  background: #eff1f1; }

.map {
  border-radius: 5px;
  position: relative; }

.map-home {
  height: 100%;
  position: relative; }

.btn-fit-bound {
  z-index: 10;
  position: fixed;
  bottom: 5%;
  right: 3%;
  background: #0079bf;
  padding: 20px;
  border-radius: 50%;
  color: #fff;
  outline: 0;
  border: none;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2); }

.btn-fit-bound:focus {
  outline: 0;
  border: none; }

.map-report {
  height: 100%;
  width: 100%;
  position: relative; }

.map-modal {
  top: 15%;
  left: 15%;
  height: 80%;
  width: 70%;
  position: fixed; }

.map-modal-radius {
  top: 30%;
  left: 20%;
  height: 60%;
  width: 60%;
  position: fixed; }

.map-alarm-replay {
  top: 0%;
  left: 20%;
  height: 100%;
  width: 100%; }

.map-modal-none {
  top: 15%;
  left: 10%;
  height: 0%;
  width: 0%;
  position: fixed; }

.dingigl-popup {
  max-width: 300px;
  height: 30px;
  padding: 5px;
  padding-bottom: 0px; }

.dingigl-popup-content {
  font-family: "Poppins", sans-serif; }

.dingigl-marker {
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0; }

.svg:not(:root) {
  overflow: hidden;
  visibility: hidden; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-deep-orange {
  color: #e0740e; }

.text-green {
  color: #4cc474; }

.text-deep-green {
  color: #098833; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-color {
  background: #eff1f1; }

.topbar-container {
  height: 10vh;
  background: #fff;
  border-bottom: 1px solid #d8dad9;
  display: grid;
  -webkit-align-content: center;
          align-content: center;
  grid-template-columns: 30% 40% 10% 20%; }

.sb-avatar__text {
  border-radius: 4px; }

.app-topbar-title {
  font-size: 20px;
  letter-spacing: 2px;
  -webkit-align-self: center;
          align-self: center;
  text-align: center; }

.profile-name-top {
  padding-left: 10px;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  background: #fff; }

.btn-profile {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-self: right;
  grid-column-gap: 10px;
  border-color: 1px solid #eeeeee; }
  .btn-profile:hover {
    cursor: pointer; }

.btn-notification {
  outline: 0;
  padding-left: 5px;
  padding-right: 5px; }
  .btn-notification:focus {
    outline: 0; }

.button-logout {
  outline: 0;
  border: none;
  padding: 10px 15px;
  font-size: 15px;
  border-radius: 5px;
  background: white;
  color: black; }

.profile-topbar-container {
  -webkit-justify-content: center;
          justify-content: center;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-row-gap: 15px;
  text-align: center; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-deep-orange {
  color: #e0740e; }

.text-green {
  color: #4cc474; }

.text-deep-green {
  color: #098833; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-color {
  background: #eff1f1; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-deep-orange {
  color: #e0740e; }

.text-green {
  color: #4cc474; }

.text-deep-green {
  color: #098833; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-color {
  background: #eff1f1; }

.text-shadow {
  background: rgba(238, 238, 238, 0);
  padding: 5px;
  border: none;
  border-radius: 5px; }
  .text-shadow:focus {
    outline: 0; }

.navbar-form {
  background: #eeeeee;
  width: auto;
  color: #292b2c;
  border-radius: 4px;
  margin-left: 10px; }

.form-controll {
  outline: 0;
  width: 100%;
  font-size: 14px;
  background: #eff1f1;
  border: none; }

.input-search {
  padding: 15px;
  display: grid;
  grid-template-columns: 30px calc(100% - 60px) 30px;
  background: #eff1f1;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
  border-radius: 4px; }

.text-with-icon-in-modal {
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 11fr;
  border: 1px solid rgba(203, 207, 206, 0.404);
  -webkit-align-items: center;
          align-items: center;
  margin: 10px 0px;
  border-radius: 5px;
  padding-left: 10px; }

.text-with-icon-in-modal-large {
  height: auto;
  display: grid;
  grid-template-columns: 1fr 11fr;
  border: 1px solid rgba(203, 207, 206, 0.404);
  margin: 10px 0px;
  border-radius: 5px;
  padding-left: 10px;
  padding: 5px;
  text-align: center; }

.icon-text-input-box-large {
  height: auto;
  font-size: 15px;
  margin: 3px; }

.icon-text-input-box {
  font-size: 15px;
  margin: 3px; }

.grey-background {
  background: #eeeeee;
  padding: 10px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  width: 95%; }
  .grey-background:focus {
    outline: 0; }

.dropdown-with-title {
  margin-top: 10px; }

.title-over-dropdown {
  font-size: 10px;
  margin-left: 10px; }


.btn-notification{
    justify-self: center;
    font-size: 30px;
}

.notification-item{
    height: auto;
    width: 250px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 5px;
    box-shadow: 0 4px 4px 0 #eee;
}

.notification-item:hover{
    cursor: pointer;
}


.notification-item-icon{
    height: 60px;
    -webkit-align-content: center;
            align-content: center;
    font-size: 30px;
    padding: 10px;
}

.notification-item-details-title{
    height: auto;
    display: grid;
    font-size: 15px;
}

.notification-item-details{
    display: grid;
    grid-row-gap: 5px;
    line-height: 1.2rem;
    -webkit-align-content: center;
            align-content: center;
    font-size: 12px;
    /* grid-template-rows: 2fr 1fr 1fr; */
}


.btn-noti-see-more{
    padding: 10px;
    width: 100%;
    color: blue;
    text-align: center;
}

.popup-content.notification-container-content {
    width: inherit !important;
    height: 400px;
    overflow: auto;
}

