@import "../../assets/styles/constants.scss";

.topbar-container {
  height: 10vh;
  background: #fff;
  border-bottom: 1px solid rgb(216, 218, 217);
  display: grid;
  align-content: center;
  grid-template-columns: 30% 40% 10% 20%;
}

.sb-avatar__text {
    border-radius: 4px;
}

.app-topbar-title {
  font-size: 20px;
  letter-spacing: 2px;
  align-self: center;
  text-align: center;
}

.profile-name-top {
  padding-left: 10px;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  background: #fff;
}

.btn-profile {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-self: right;
  grid-column-gap: 10px;
  &:hover {
    cursor: pointer;
  }
  border-color: 1px solid $greyColor;
}

.btn-notification {
  outline: 0;
  padding-left: 5px;
  padding-right: 5px;
  &:focus {
    outline: 0;
  }
}

.button-logout {
  outline: 0;
  border: none;
  padding: 10px 15px;
  font-size: 15px;
  border-radius: 5px;
  background: rgb(255, 255, 255);
  color: black;
}

.profile-topbar-container{
  justify-content: center;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-row-gap: 15px;
  text-align:center;
}
