.search-result-list{
    position: fixed;
    top: 9.5vh;
    left: 70px;;
    // width: calc  (100vw -60px) ;
    width: 27.5vw;
    max-height: 400px;
    max-width: 600px;
    z-index: 10;
    overflow: auto;;
    background: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}



.search-result-list-item-vehicle{
    padding: 10px;
    display: grid;
    grid-template-columns:  1fr 4fr;
    height: auto;
    text-align: left;
    border-bottom: 1px rgba(221, 219, 219, 0.61) solid;
}


.search-result-list-item-vehicle:hover{
    background: rgb(231, 236, 234);
}

.search-vehicle-item-details{
    display: grid;
    grid-template-rows: 2fr 1fr;
}

.search-vehicle-item-icon{
    font-size: 20px;
    align-self: center;
    justify-self: center;
}

.search-vehicle-item-title{
    font-size: 15px;
    margin-left: 5px;
    align-self: center;
}

.search-vehicle-item-driver{
    font-size: 14px;
    align-self: center;
}


