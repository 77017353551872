@import "../../assets/styles/constants";

.table {
  border: none;
  overflow: auto;
}

@mixin tableBasic {
  border: none;
  overflow: auto;
  overflow: auto;
  border-radius: 5px;
  @include shadow();
  background: #fff;
}



.tablename-btn-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.header-text {
  font-size: 20px;
  align-self: center;
}

.table-alarm {
  @include tableBasic();
  height: 70vh;
}

.table-maintenance {
  @include tableBasic();
  height: 40vh;
}

.table-fuel {
  @include tableBasic();
  height: 70vh;
}


.table-bill {
  @include tableBasic();
  height: 50vh;
}


.table-support {
  @include tableBasic();
  height: 70vh;
}



.table td {
  border: none;
  // height: 50px;
  font-size: 12px;
  // margin-bottom: 10px;
  align-items: center;
}

.td-center{
  justify-content: center;
  justify-items: center;
  justify-self: center;
}

.table th {
  font-size: 14px;
  border: none;
}

.replay-icon {
  font-size: 20px;
}

.row:nth-child(even) {
  background-color: #f2f2f2;
}




.center-td{
  justify-self: center;
}

.btn-load-more{
  text-align: center;
  width: 100%;
  color: $blueColor;
}
.btn-load-more:focus{
  outline: 0;
  border: none;

}

@mixin issueCommon {
  border: 1px solid;
  padding: 5px 20px;
  border-radius: 4px;
}

.issue-open {
  color: $orangeColor;
  @include issueCommon();
}
.issue-reopen {
  color: $blueColor;
  @include issueCommon();
} 
.issue-resolved {
  color: $greenColor;
  @include issueCommon();
}
.issue-closed {
  color: $redColor;
  @include issueCommon();
}
.issue-duplicate {
  color: $greenColor;
  @include issueCommon();
}


.table-item-no-data{
  justify-content: center;
  text-align: center !important;
  font-size: 15px !important;
  width: 100%;
}



.due-status{
  padding: 5px 15px;
  text-align:center;
  border-radius:5px;
  // @include shadow();
}