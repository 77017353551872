@import "./constants.scss";
.app-container {
  background: #eff1f1 !important;
  display: grid;
  grid-template-columns: 60px auto;
  height: 100vh !important;
  font-family: "Poppins", sans-serif !important;
}

.app-sidebar {
  width: 60px;
  height: 100vh;
  background: #37474f;
}

.app-top-mainbar {
  display: grid;
  grid-template-rows: 10% 90%;
}

.app-topbar {
  // @include shadow();
}

.app-mainbar {
}

.text-red {
  color: #fe3935;
}

.text-black {
  color: #37474f;
}

.text-blue {
  color: #0079bf;
}

.text-green {
  color: rgb(76, 196, 116);
}

.text-green-deep {
  color: rgb(69, 179, 105);
}

.background-color {
  background: #eff1f1;
}





::-webkit-scrollbar-track {
  background-color: #ddd;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #ddd;
}

::-webkit-scrollbar-thumb {
  background-color: #0079BF;
}
