@import "../../assets/styles/constants.scss";

@mixin shadow {
  -webkit-box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  -moz-box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
}

@mixin modalContainerBasic($background) {
  position: fixed;
  background: $background;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 5px;
  @include shadow();
}

.modal-loader {
  background: #fff;
  display: block;
}

.modal-loader-container {
  position: fixed;
  background: transparent;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 5px;
}

//location share
.modal-locationshare-container {
  @include modalContainerBasic(#fff);
  height: auto;
  width: 50%;
  display: grid;
  font-size: 12px;
  grid-template-rows: 1fr;
  padding: 15px;
}

.modal-location-share-url {
  display: grid;
  grid-template-columns: 5fr auto;
}

//modal radius set
.modal-radius-set {
  position: fixed;
  background: #fff;
  top: 20%;
  left: 20%;
  height: 70%;
  width: 60%;
  display: grid;
  grid-template-rows: 20% 80%;
}

.modal-radius-set-topbar {
  display: grid;
  grid-template-columns: 80% 20%;
}
.modal-radius-set-mainbar {
}

// fuel
.modal-fuel-container {
  @include modalContainerBasic(#fff);
  height: auto;
  width: 400px;
  display: grid;
  font-size: 12px;
  grid-template-rows: 1fr;
  padding: 15px;
}

.image-with-upload-btn {
  display: grid;
  grid-template-rows: 5fr 1fr;
}

//log view
.modal-logview-container {
  @include modalContainerBasic(#fff);
  width: 400px;
  padding: 30px;
}

//trend
.modal-trendview-container {
  @include modalContainerBasic(#fff);
  height: auto;
  width: auto;
  padding: 30px;
}

.modal-trend-resource-date {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  padding: 10px;
}

//paper
.modal-vehiclepaper-container {
  @include modalContainerBasic(#fff);
  display: grid;
  grid-template-rows: 40px 60px 250px 60px;
  grid-row-gap: 10px;
  width: 400px;
  height: auto;
  padding: 10px 20px;
}

.vehicle-paper-image {
  height: 200px;
  width: auto;
}

.btn-choose-file {
  background: #fff;
}

.date-text-paper-update {
  padding: 15px;
}

/* maintenance entry */

.modal-maintenance-container {
  @include modalContainerBasic(#fff);
  display: grid;
  margin: 10px;
  width: 400px;
  font-size: 12px;
  grid-template-rows: 1fr;
  padding: 20px;
}

.modal-title-cross {
  display: grid;
  grid-template-columns: 8fr 1fr;
  font-size: 18px;
}

.modal-title-cross:hover {
  cursor: pointer;
}

.modal-title {
  margin: 10px;
  font-size: 20px;
}

.modal-cross {
  font-size: 20px;
  align-self: center;
  justify-self: right;
}

.modal-replay-details {
  height: auto;
  display: grid;
  width: 25%;
  grid-row-gap: 25px;
  padding: 15px;
  z-index: 11;

  background: #fff;
  position: fixed;
  top: 15%;
  left: 15%;
  border-radius: 5px;
}

.alarm-replay-title-time-cross {
  display: grid;
  grid-template-columns: 43% 44% 15%;
  align-items: baseline;
}

.alarm-replay-title {
  color: $redColor;
  font-size: 18px;
}

.alarm-replay-time {
  font-size: 12px;
  color: rgb(117, 126, 120);
  justify-self: right;
}

.alarm-replay-close {
  font-size: 20px;
  justify-self: right;
  padding: 10px;
}

.alarm-replay-type-value {
  margin-top: 10px;
  font-size: 14px;
  color: $blackColor;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 5px;
}

.alarm-replay-type {
}

.alarm-replay-value {
}

.alarm-replay-image {
  margin-top: 10px;
  height: auto;
  justify-self: center;
  // padding: 10px;
  width: auto;
}

.alarm-replay-vehicletitle {
  margin-top: 10px;
  font-size: 14px;
}

.alarm-replay-license {
  margin-top: 10px;
  font-size: 14px;
}

.alarm-replay-driver {
  margin-top: 10px;
  font-size: 14px;
}

.btn-replay-play-pause {
  padding: 10px;
  font-size: 15px;
  text-align: center;
  align-self: center;
  justify-items: center;
  background: #eee;
}

.btn-replay-play-pause:hover {
  cursor: pointer;
}
//modal vehicle details

.modal-vehicle-details-container {
  position: fixed;
  width: 30%;
  height: 88%;
  right: 10px;
  bottom: 1%;
  padding: 15px;
  line-height: 1.2;
  word-spacing: 2px;
  letter-spacing: 0.8px;
  background: #fff;
  border-radius: 5px;
  overflow: auto;
  @include shadow();
}

.speedometer-container {
  height: 200px;
  width: 200px;
}

.vehicle-details-topbar-container {
  align-items: center;
  display: grid;
  grid-template-rows: 20px 70px 30px;
  grid-row-gap: 5px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(178, 189, 189, 0.274);
}

.vehicle-details-title-cross {
  font-size: 20px;
  display: grid;
  align-items: center;
  grid-template-columns: auto 24px;
}

.vehicle-details-title {
  text-align: left;
  display: grid;
}

.vehicle-details-timeago {
  font-size: 10px;
  line-height: 1.3;
}

.vehicle-details-driver-vehicle {
  line-height: 1.5;
  align-items: center;
  font-size: 14px;
  display: grid;
  color: rgb(88, 90, 92);
  grid-row-gap: 1px;
}

.mini-person-icon {
  font-size: 12px;
}

.btn-track {
  @include shadow();
  font-size: 16px;
  margin: 15px 0px;
  width: 100%;
  color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  background: $blueColor;
  outline: 0;
}

.btn-track:hover {
  transition: 300ms;
}

.btn-track:focus {
  transition: 300ms;
  border: none;
  outline: 0;
}

.btn-exit-track {
  margin: 15px 0px;
  color: $redColor;
  width: 100%;
  padding: 15px 10px;
  border-radius: 5px;
  border: 1px solid $redColor;
  background: #fff;
  outline: 0;
}

.btn-exit-track:hover {
  background: $redColor;
  color: #fff;
  transition: 300ms;
}

.btn-exit-track:focus {
  outline: 0;
}

.vehicle-details-title-over-data {
  margin: 20px 0px;
  font-size: 12px;
}

.vehicle-details-info-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  border-bottom: 1px solid rgba(165, 168, 166, 0.322);
  margin-bottom: 10px;
}

.dashboard-single-item-container {
  display: grid;
  grid-template-rows: 1fr 3fr;
  grid-row-gap: 10px;
  font-family: "Poppins", sans-serif !important;
}

.dashboard-item-title {
  font-size: 10px;
  color: rgb(2, 2, 2);
}

.dashboard-item-data {
  font-size: 20px;
  align-items: center;
}

.device-details-image-container {
  display: grid;
  grid-template-rows: 4fr auto;
  grid-row-gap: 5px;
}

.device-details-image {
  height: 150px;
}

.device-details-switch-btn {
  color: $blueColor;
  height: 40px;
  width: 70%;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  outline: 0;
  @include shadow();

  &hover {
    outline: 0;
  }
  &::focus {
    outline: 0;
  }
}

.loader-spinner-anywhere {
  align-content: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
}

.paper-view-modal-img {
  height: 230px;
  width: 200px;
}

//modal trip details

.modal-trip-details {
  display: grid;
  background: #fff;
  padding: 10px;
  align-items: center;
  grid-template-rows: auto auto auto auto;
  grid-row-gap: 2%;
  overflow: auto;
  border-radius: 4px;
  z-index: 11;
  line-height: 1.2;
  word-spacing: 2px;
  letter-spacing: 1px;
  @include shadow();
}

.trip-details-title-cross {
  font-size: 20px;
  display: grid;
  align-items: center;
  grid-template-columns: auto 24px;
  border-bottom: solid 1px $greyColor;
}

.trip-details-vehicle-name {
  font-size: 12px;
  line-height: 1.5;
  margin: 10px 2px;
  color: rgba(69, 71, 71, 0.801);
}

trip-details-title {
  text-align: left;
  display: grid;
}

.trip-details-srcdest-container {
  display: grid;
  font-size: 14px;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 10px;
}

.trip-details-srcdest-single {
  display: grid;
  align-items: center;
  grid-template-columns: 8% 72% 20%;
}

.trip-details-srcdest-time {
  display: grid;
  font-size: 10px;
  background: $lightBlueColor;
  color: $blueColor;
  padding: 8px 4px;
  letter-spacing: 1px;
  border-radius: 4px;
  text-align: center;
}

.trip-details-info-container {
  border-top: 1px solid $greyColor;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr auto;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  border-bottom: 1px solid rgba(165, 168, 166, 0.322);
  margin-bottom: 10px;
  padding-top: 10px;
}

.report-single-item-container {
  display: grid;
  grid-template-rows: 1fr 3fr;
  grid-row-gap: 10px;
  font-family: "Poppins", sans-serif !important;
}

//trip summary

.modal-trip-summary {
  display: grid;
  background: #fff;
  @include shadow();
  height: inherit !important;
  overflow: auto;
  @include shadow();
  border-radius: 5px;
  font-family: "Poppins", sans-serif !important;
  padding: 0px 12px;
}

.trip-summary-single {
  display: grid;
  border-bottom: 1px solid $greyColor;
  padding: 12px 0px;
  grid-row-gap: 5px;
  line-height: 1.5;
  word-spacing: 2px;
  letter-spacing: 0.5px;
  max-height: 200px;
  &:hover {
    cursor: pointer;
  }
}

.trip-srcdest-time {
  padding: 7px 0px;
  border-radius: 4px;
  font-size: 11px;
  letter-spacing: 0.5px;
  word-spacing: 0px;
  text-align: center;
}

.icon-big {
  font-size: 16px;
  align-self: center;
}

.single-trip-single-row-major {
  display: grid;
  align-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  grid-column-gap: 2%;
  grid-template-columns: 6% 68% 20%;
}
.single-trip-single-row-minor {
  display: grid;
  align-content: center;
  align-items: center;
  font-size: 11px;
  color: rgba(112, 121, 120, 0.842);
  grid-template-columns: 10% 65% 25%;
}

.report-single-trip {
  display: grid;
  grid-template-rows: 1fr 0.8fr 0.8fr 0.8fr 1fr;
}
