
.side-bar-icon-container-logo a {
    cursor: pointer;
}

.sidebar {
    height: 100vh;
    width: 60px;
    /* position: fixed; */
    z-index: 1;
    background-color: #fff;
    overflow-x: hidden;
    display: grid;
    grid-template-rows: repeat(10 , 2fr )  10fr 2fr;
    align-items: center;
    justify-items: center;
  }


  .a:focus{
    background:   #0079BF;
  }

  .side-menus {
    width: 100%;
}

  .sidebar div.side-menus a {
    font-size: 28px;
	  color:  #37474f;
    display: block;
    padding: 26%;
    justify-content: center;
  }


  .sidebar div.side-menus a:hover{
    padding: 26%;
    cursor: pointer;
	  color: #fff;
    transition: 300ms;
    background:  #0079BF;  ;
    text-decoration: none;
  }

  .sidebar div.side-menus a:focus{
    cursor: pointer;
    color:    #0079BF;
    background:  #fff;
    transition: 300ms;
    text-decoration: none;
  }


  .side-bar-icon-container-logo{
    padding: 10px;
  }
