@import "../../assets/styles/constants";

.dropdown-basic-container {
  display: grid;
  grid-template-columns: 1.5fr 8.5fr;
  letter-spacing: 1px;
  background: #fff;
  align-items: center;
  @include shadow();
  border-radius: 4px;
}

.datepicker-container {
  display: grid;
  grid-template-columns: 8.5fr 1.5fr;
  letter-spacing: 1px;
  background: #fff;
  align-items: center;
  @include shadow();
  border-radius: 4px;
}

.dropdown-basic-icon-container {
  display: grid;
  align-content: center;
  justify-items: center;
  font-size: 25px;
}

.dropdown-basic-title-data {
}

.dropdown-basic-title {
  font-size: 10px;
  padding: 5px 10px;
}

.dropdown-basic-data {
  padding: 2px 5px;
  font-size: 14px;
}

.dropdown-basic-selection {
  background: #fff;
  border: none;
  outline: 0;
  width: 100%;
}

.dropdown-basic-option {
}

.date-picker {
  font-size: 14px;
  outline: 0;
  border: none;
  width: 100%;
  padding: 2px 10px;
}

.dropdown-severity-container {
  -webkit-align-items: center;
  align-items: center;
  justify-self: center;
  display: grid;
  grid-template-columns: 1fr 9fr;
  transition: 0.3s;
  position: relative;
}

.severity-status-color {
  position: absolute;
  width: 11px;
  height: 11px;
  display: block;
  left: 10px;
  border-radius: 50%;
}

.dropdown-severity-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  padding: 15px 30px;
  font-size: 12px;
  outline: 0;
  background: rgba(55, 71, 79, 0.07);
  border-radius: 4px;
  background-image: url("../../assets/icons/down-arrow.png");
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  width: 140px;
  height: 55px;
}

.dropdown-resources-normal {
  padding: 5px 10px;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  font-size: 14px;
  outline: 0;
  border: none;
}

.dropdown-resources-modal {
  padding: 10px;
  padding-left: 10px;
  background: $greyColor;
  border-radius: 5px;
  outline: 0;
  border: none;
  width: 100%;
}

.dropdown-modal-container {
  background: $lightGreyColor;
  border-radius: 5px;
  margin: 5px;
}

.dropdown-modal-selection {
  background: $lightGreyColor;
  border: none;
  outline: 0;
  padding: 10px;
  width: 100%;
}

.dropdown-modal-option {
  background: $lightGreyColor;
  padding: 10px;
  height: 40px;
}

.dropdown-with-icon-in-modal {
  display: grid;
  grid-template-columns: 1fr 12fr;
  background: $greyColor;
  align-items: center;
  margin: 0px 0px;
  padding: 4px 8px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.icon-dropdown-box {
  font-size: 15px;
  margin-left: 5px;
}

.date-picker-modal {
  padding: 10px;
  width: 100%;
  text-align: center;
  border: none;
  outline: 0;
  background: $greyColor;
}

.icon-date-alarm {
  font-size: 25px;
}
