@import "../../assets//styles//constants.scss";
.map {
  border-radius: 5px;
  position: relative;
}

.map-home {
  height: 100%;
  position: relative;
}

.btn-fit-bound {
  z-index: 10;
  position: fixed;
  bottom: 5%;
  right: 3%;
  background: $blueColor;
  padding: 20px;
  border-radius: 50%;
  color: #fff;
  outline: 0;
  border: none;
  @include shadow();
}

.btn-fit-bound:focus {
  outline: 0;
  border: none;
}

.map-report {
  height: 100%;
  width: 100%;
  position: relative;
}

.map-modal {
  top: 15%;
  left: 15%;
  height: 80%;
  width: 70%;
  position: fixed;
}
.map-modal-radius {
  top: 30%;
  left: 20%;
  height: 60%;
  width: 60%;
  position: fixed;
}

.map-alarm-replay {
  top: 0%;
  left: 20%;
  height: 100%;
  width: 100%;
  //position: fixed;
}

.map-modal-none {
  top: 15%;
  left: 10%;
  height: 0%;
  width: 0%;
  position: fixed;
}

.dingigl-popup {
  max-width: 300px;
  height: 30px;
  padding: 5px;
  padding-bottom: 0px;
}

.dingigl-popup-content {
  font-family: "Poppins", sans-serif;
}


.dingigl-marker {
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
}

.svg:not(:root) {
  overflow: hidden;
  visibility: hidden;
}
