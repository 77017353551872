@import "../../assets/styles/constants";

@mixin shadow {
  -webkit-box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  -moz-box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
}

.btn-exit-tracker {
  border: 1px solid #0079bf;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 5px;
  z-index: 11;
  background: transparent;
  background: #fff;
  color: #0079bf;
  font-style: bold;
  align-content: center;
  position: fixed;
  display: block;
  margin-bottom: 0%;
  position: fixed;
  right: 40%;
  top: 90%;
  transition: all 0.2s ease-in 0s;
  z-index: 9999;
  cursor: pointer;
}
.btn-exit-tracker:hover {
  background: #0079bf;
  color: #fff;
}

.btn-pay {
  color: #0079bf;
  border-radius: 5px;
  padding: 8px 30px;
  background: #fff;
}

.btn-pay:hover {
  // box-shadow: 0 5px 5px 0 rgb(162, 170, 169);
  transition: 30ms;
}



@mixin buttonWithIcon($background, $color, $justification, $padding) {
  justify-self: $justification;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 15px;
  padding: $padding;
  align-items: center;
  border-radius: 5px;
  background: $background;
  color: $color;
}

.btn-icon-txt-blue-filled {
  @include buttonWithIcon($blueColor, $whiteColor, right, 2px);
  padding-left: 15px;
  padding-right: 30px;
  height: 50px;
  min-width: 100px;
  &:hover {
    cursor: pointer;
    @include shadow();
  }
  transition: 0.3s;
}

.btn-download-report{
  padding: 10px 20px;
  height: 40px;
  min-width: 100px;
  margin:10px;
  background:$textBlueColor;
  color:$whiteColor;
  border-radius:4px;
  font-size:14px;
  &:hover {
    cursor: pointer;
    @include shadow();
  }
}

.btn-icon-txt-white-outlined-left {
  @include buttonWithIcon($whiteColor, $blueColor, left, 8px);
  padding: 2px 10px;
  &:hover {
    cursor: pointer;
  }
  transition: 0.3s;
}

.btn-icon-txt-white-outlined-right {
  @include buttonWithIcon($whiteColor, $blueColor, right, 8px);
  padding: 2px 10px;
  &:hover {
    cursor: pointer;
    transition: 0.3s;
  }
}

.valid-paper {
  @include buttonWithIcon($lightBlueColor, $blueColor, left, 6px);
  padding: 2px 10px;
  padding-right: 30px;
  // @include shadow();
}

.expired-paper {
  @include buttonWithIcon($lightRedColor, $redColor, left, 6px);
  padding: 2px 10px;
  padding-right: 30px;
  // @include shadow();
}

.warning-paper {
  @include buttonWithIcon($lightOrangeColor, $orangeColor, left, 6px);
  padding: 2px 10px;
  padding-right: 30px;
  // @include shadow();
}


.save-btn {
  margin-top: 20px;
  background: rgb(68, 125, 209);
  color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  padding-top: 15px;
  display: grid;
  grid-template-columns: 1fr 10fr;
}

.save-text {
  margin-right: 15%;
}

.btn-icon{
  font-size: 18px;
}

.btn-signin{
  justify-self: right;
  background: #0079bf;
  color: #fff;
  padding: 15px 20px;
  border-radius: 4px;
  &:hover{
    @include shadow();
    transition: 0.3s;
  }
}



.btn-group-cntrl-map{
  top : 15%;
  left: 80px;
  position: fixed;
  display: grid;
  grid-template-rows: 1fr;
  border-radius: 3px;
}



.btn-zoom-in{
  color: #131313;
  background: #fff;
  font-size: 24px;
  padding: 6px 5px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  transition: 0.1s;
}

.btn-zoom-out{

  background: #fff;
  font-size: 24px;
  padding: 6px 5px;
  transition: 0.1s;

}

.btn-my-location{

  background: #fff;
  font-size: 20px;
  padding: 6px 5px;
  padding-left: 7px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: 0.1s;
}

.btn-my-location:hover{
  background: $textBlueColor;
  color: #fff;
  cursor: pointer;
}



.btn-zoom-in:hover{
  background: $textBlueColor;

  color: #fff;
  cursor: pointer;
}



.btn-zoom-out:hover{
  background: $textBlueColor;
  color: #fff;
  cursor: pointer;
}

